/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  Button,
  DisplayText,
  Header,
  HeroImage,
  HeroVideo,
  LayoutUI,
  LeadText,
  Stack,
  TextStyle,
  WithFloatingArrow
} from '../../../ui-kit';

import ROUTES from '../../../utils/routes';

import FlagsImage from './images/flags.jpg';
import ScholarshipImage from './images/scholarship.jpg';
import ElementaryImage from './images/elementary.jpg';
import MiddleSchoolImage from './images/middle-school.jpg';
import YoungAdultImage from './images/young-adult.jpg';
import PreferredSchoolImage from './images/preferred-school.jpg';
import MaterialsImage from './images/materials.jpg';

import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';

import * as styles from './Education.module.scss';

const VIDEO_URL =
  'https://storage.googleapis.com/proj-happiness/assets/iStock-456044712%20480.mov';

const CONTENT = {
  THREE_UP_PROGRAMS: [
    {
      color: 'red',
      description:
        'The 9-lesson series teaches life-long skills that influence happiness and develop SEL competencies for elementary school-aged children.',
      image: ElementaryImage,
      title: 'Elementary',
      url: ROUTES.educationProgram('elementary')
    },
    {
      color: 'blue-dark',
      description:
        'This teacher-friendly curriculum, including assessment tools, prepares students for success in life while helping participants develop core SEL competencies.',
      image: MiddleSchoolImage,
      title: 'Middle School',
      url: ROUTES.educationProgram('middle-school')
    },
    {
      color: 'yellow',
      description:
        'Our online course and Project Happiness Handbook are the ideal bundle to activate self-discovery and provide the tools to enhance personal happiness, no matter the external conditions.',
      image: YoungAdultImage,
      title: 'Young Adult',
      url: ROUTES.educationProgram('young-adult')
    }
  ],
  CALLS_TO_ACTION: [
    {
      description:
        'Want to take it to the next level? Implement Project Happiness school-wide using our all-inclusive packages.',
      href: ROUTES.preferredSchoolProgram(),
      image: PreferredSchoolImage,
      title: 'Preferred School Programs'
    },
    {
      description:
        'Already know what you want? Purchase our materials either a-la-carte, or in discounted bundles.',
      href: ROUTES.shop(),
      image: MaterialsImage,
      title: 'Shop Education Materials'
    }
  ]
};

const Education = () => (
  <Layout>
    <Navbar color="white" />
    <MobileNavbar color="white" />
    <HeroVideo src={VIDEO_URL}>
      <LayoutUI>
        <Stack distribution="center" alignment="center" vertical>
          <TextStyle center color="white">
            <DisplayText>Teach Happiness</DisplayText>
          </TextStyle>

          <TextStyle center color="white">
            <p>Facilitate a child’s success in school and in life</p>
          </TextStyle>
        </Stack>
      </LayoutUI>
    </HeroVideo>

    <div className={styles.Programs}>
      <LayoutUI>
        <div className={styles.ThreeUpContainer}>
          {CONTENT.THREE_UP_PROGRAMS.map(
            ({ title, image, color, description, url }) => (
              <div className={styles.ThreeUpItem} key={title}>
                <a href={url}>
                  <WithFloatingArrow>
                    {floatingArrow => (
                      <Stack vertical>
                        <img src={image} alt={title} />
                        <Header size="small">
                          <TextStyle color={color}>{title}</TextStyle>
                        </Header>
                        <p>Learn More {floatingArrow}</p>
                        <p>{description}</p>
                      </Stack>
                    )}
                  </WithFloatingArrow>
                </a>
              </div>
            )
          )}
        </div>
      </LayoutUI>

      <LayoutUI narrow>
        <TextStyle center>
          <Stack vertical spacing="loose">
            <Header>Why is Social Emotional Learning (SEL) important?</Header>
            <p>
              SEL is a process for helping our youth develop the fundamental
              skills for life-effectiveness. Socially and emotionally competent
              children are skilled in five core areas: they are self-aware and
              socially aware, are able to regulate emotions, have good
              relationship skills, demonstrate responsible decision-making
              throughout all areas of life, and can set and monitor progress
              towards goals. Early investments in SEL yield long-term dividends.
            </p>
          </Stack>
        </TextStyle>
      </LayoutUI>

      <LayoutUI narrow>
        <TextStyle center>
          <Stack vertical spacing="loose">
            <Header>What makes our programs different than the rest?</Header>
            <p>
              We took a well-rounded, comprehensive approach to teaching SEL to
              youth, based on the science of happiness – including a mindful
              moment and awe image for every chapter, among several activities
              per lesson. Additionally, our curriculum is scripted, so that
              anybody, whether or not they’ve received formal training on SEL,
              can feel confident teaching these invaluable skills.
            </p>
          </Stack>
        </TextStyle>
      </LayoutUI>

      <LayoutUI>
        <div className={styles.CallToActionContainer}>
          {CONTENT.CALLS_TO_ACTION.map(
            ({ image, title, description, href }) => (
              <div className={styles.CallToActionItem} key={title}>
                <WithFloatingArrow>
                  {floatingArrow => (
                    <a href={href}>
                      <Stack vertical>
                        <div className={styles.CallToActionImage}>
                          <img src={image} alt={title} />
                        </div>
                        <Header>{title}</Header>
                        <p>{description}</p>
                        <p>Learn More {floatingArrow}</p>
                      </Stack>
                    </a>
                  )}
                </WithFloatingArrow>
              </div>
            )
          )}
        </div>
      </LayoutUI>
    </div>

    <HeroImage image={FlagsImage} noMargin>
      <TextStyle center>
        <LayoutUI narrow>
          <Stack vertical alignment="center" distribution="center">
            <Header>Our Global Community</Header>
            <LeadText>
              Our evidence-based emotional resilience curriculum has received
              rave reviews from teachers, administrators, parents and most
              importantly students by providing proven skills to thrive in an
              ever-changing world. In over 120 countries and in every state in
              the US, students from all backgrounds are learning to reduce
              stress, regulate their emotions, develop pro-social skills and
              build on their strengths.
            </LeadText>
          </Stack>
        </LayoutUI>
      </TextStyle>
    </HeroImage>

    <div className={styles.ScholarshipContainer}>
      <div
        className={styles.ScholarshipImage}
        style={{ backgroundImage: `url(${ScholarshipImage})` }}
      />
      <div className={styles.ScholarshipContent}>
        <Stack vertical spacing="loose">
          <Header>Project Happiness Scholarship Programs</Header>
          <LeadText alignment="left">
            Made possible by generous donors, we would like to aid schools who
            have interest in bringing happiness to their school. Check to see if
            your school qualifies. If you’d like to give to the scholarship
            fund, please
            <a href={ROUTES.donate()}>donate here</a>.
          </LeadText>
          <a href={ROUTES.scholarshipApplication()}>
            <Button color="blue-dark">Apply</Button>
          </a>
        </Stack>
      </div>
    </div>
  </Layout>
);

export default Education;
